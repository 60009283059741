import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { lightTheme } from '../customTheme';
import { ResponsiveAppBar } from '../components/ResponsiveAppBar';
import Panel from '../components/Panel';
import { colors } from '../customTheme';
import { Container, Card, CardContent, List, ListItem, ListItemText, Button, Typography, Box, TextField, Divider, LinearProgress, Link } from '@mui/material';
import Report from '@mui/icons-material/Report';
import Verified from '@mui/icons-material/Verified';
import { HoverRating } from '../components/HoverRating';
import useMediaQuery from '@mui/material/useMediaQuery';
import Footer from '../components/Footer';
import AnalyzerBenefits from '../components/AnalyzerBenefits';
import AnalyzerDetails from '../components/AnalyzerDetails';
import AnalyzerVideo from '../components/AnalyzerVideo';
import NewsletterPanel from '../components/NewsletterPanel';
import { Helmet } from 'react-helmet-async';
import PATHS from "../utils/paths";
import { Analytics } from 'monolib';
// import { fetchCoverPhotoAnalysis } from "../api/client";
// import LocalCache from '../utils/LocalCache.ts';

export const Analyzer = ({ platform, getListingAnalysisCallback, canonicalUrl }) => {
  // const listingCache = useRef(new LocalCache());
  const navigate = useNavigate();
  const location = useLocation();
  const [query, setQuery] = useState('');
  const [result, setResult] = useState(null);
  // const [photoResult, setPhotoResult] = useState(null);
  // const [loadingPhotoAnalysis, setLoadingPhotoAnalysis] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const initialQueryRun = useRef(false);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isLg = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  const handleAnalyzeClick = useCallback(async (q = query) => {
    try {
      if (loading) {
        return;
      }
      if (platform.toLowerCase() !== "poshmark" && !q.includes(platform.toLowerCase())) {
        throw new Error(`Please use a ${platform} URL`);
      } else if (!(q.includes(platform.toLowerCase()) || q.includes("posh.mk"))) {
        throw new Error(`Please use a ${platform} URL`);
      } else if (q.includes("closet")) {
        throw new Error(`Please use a listing URL not a closet URL`);
      }
      setLoading(true);
      setError(null);

      Analytics.instance.track("analyze_click", { platform })

      const res = await getListingAnalysisCallback({ listingURL: q });

      setResult(res);
      setLoading(false);

      // Update URL with query parameter
      const searchParams = new URLSearchParams(location.search);
      searchParams.set('q', q);
      navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
    } catch (error) {
      if (error.message.includes("500")) {
        setError("Invalid Url");
      } else if (error.message.includes("4")) {
        setError("System error");
      } else {
        setError(error.message);
      }
      setLoading(false);
    }
  }, [getListingAnalysisCallback, loading, location.pathname, location.search, navigate, platform, query]);

  useEffect(() => {
    if (!initialQueryRun.current) {
      const searchParams = new URLSearchParams(location.search);
      const queryParam = searchParams.get('q');

      if (queryParam) {
        setQuery(queryParam);
        handleAnalyzeClick(queryParam);
      }
      initialQueryRun.current = true;
    }
  }, [location.search, handleAnalyzeClick]);

  // useEffect(() => {
  //   const analyzePhoto = async () => {
  //     const cachedPhotoFeedback = listingCache.current.get(result?.coverShot)

  //     if (cachedPhotoFeedback) {
  //       setPhotoResult(cachedPhotoFeedback);
  //     } else if (!photoResult && result) {
  //       setLoadingPhotoAnalysis(true);
  //       const res = await fetchCoverPhotoAnalysis({ photoURL: result.coverShot });
  //       setLoadingPhotoAnalysis(false);
  //       setPhotoResult(res);
  //       listingCache.current.set(result.coverShot, res);
  //     }
  //   };
  
  //   const shouldSkipAnalysis = () => {
  //     const searchParams = new URLSearchParams(window.location.search);
  //     return searchParams.has('skipPhotoAnalysis'); // Change 'skipPhotoAnalysis' to the specific query param you want to check
  //   };
  
  //   if (platform.toLowerCase() === "poshmark" && !shouldSkipAnalysis()) {
  //     analyzePhoto();
  //   }
    
  // }, [result, photoResult, platform]);

  const renderHeading = () => {
    return (
      <Card variant="outlined">
        <CardContent style={{ display: 'flex', flexDirection: 'column',  margin: '20px'}}>
          <Typography mb={2} variant='h1' style={{ fontSize: "2rem", fontFamily: "Libre Baskerville"}} >{platform} Listing Analyzer</Typography>
          <Typography mb={2} variant='body1' color="textSecondary">
            {`Struggling with your listings?`}
          </Typography>
          <Typography mb={4} variant='body1' color="textSecondary">
            {`Get insights to optimize, boost traffic, and sell more—then sell some more.`}
          </Typography>
          <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} alignItems={isMobile ? 'stretch' : 'center'}>
            <TextField
              size="small"
              label="Enter a listing URL to get feedback..."
              variant="outlined"
              fullWidth
              value={query}
              style={{
                paddingRight: '12px',
              }}
              onChange={(e) => setQuery(e.target.value)}
              error={error ? true : false}
              helperText={error}
            />

            <Button
              variant="contained"
              color="primary"
              onClick={() => handleAnalyzeClick()}
              style={{
                borderRadius: 10,
                height: 44,
                width: isMobile ? '100%' : 220,
                marginLeft: isMobile ? 0 : 10,
                marginTop: isMobile ? 10 : 0,
                boxShadow: 'none',
                backgroundColor: colors.mineShaft,
              }}
            >
              Analyze
            </Button>
          </Box>
        </CardContent>
      </Card>
    )
  }

  // const renderPhotoAnalysisResults = () => {
  //   return(
  //     <Container style={{ marginTop: "10px", marginBottom: "10px" }}>
  //       {
  //         loadingPhotoAnalysis ?
  //           <>
  //             <CircularProgress color="secondary" />
  //             <Typography variant="h6">Performing photo analysis</Typography>
  //           </>
  //         :
  //         <List>
  //         { 
  //           photoResult?.rules?.map((item, index) => (
  //             <React.Fragment key={index}>
  //               <ListItem key={index}
  //                 secondaryAction={
  //                   item.value ? <Verified style={{ color: '#33cc99' }} /> : <Report style={{ color: '#f64f45' }} />
  //                 }>
  //                 <ListItemText
  //                   key={index}
  //                   primary={item.title}
  //                   secondary={
  //                     <React.Fragment key={index}>
  //                       {item.subtitle}
  //                       {item.improvements && (
  //                         <ul style={{ marginTop: '15px' }}>
  //                           {item.improvements.map((improvement, idx) => (
  //                             <li key={idx} style={{ marginBottom: '7px' }}>{improvement}</li>
  //                           ))}
  //                         </ul>
  //                       )}
  //                     </React.Fragment>
  //                   }>
  //                 </ListItemText>
  //               </ListItem>
                
  //             </React.Fragment>
  //           ))}
  //           </List>
  //       }
  //     </Container>  
  //   )
  // }

  const renderResults = () => {
    return (
      <Box display="flex" flexDirection={isLg ? "row" : "column"} alignItems="stretch">
        {loading &&
          <Card variant="outlined" style={{ marginTop: '12px', flex: 1 }}>
            <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Box sx={{ width: '100%' }}>
                <LinearProgress />
              </Box>
            </CardContent>
          </Card>}

        {!loading && result?.rules?.length > 0 &&
          <>
            <Card variant="outlined" padding={2} style={{ marginTop: '12px', marginRight: isLg ? '12px' : '0px' }}>
              <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography variant="h5" fontFamily={"Libre Baskerville"} style={{ marginTop: '20px', marginBottom: '20px'}}>Listing Score</Typography>

                <HoverRating value={result.score.rating} label={result.score.word_rating} style={{ paddingTop: '12px' }} />

                <Typography variant="body1" color="textSecondary" textAlign="center" mt={1} padding={2} style={{ maxWidth: 240 }}>
                  {result.score.sentence_rating}
                </Typography>
                <Link href={`${PATHS.GUIDES}/how-create-poshmark-listings-that-sell`} variant="body2">Learn to Create Listings that Sell</Link >
              </CardContent>
            </Card>

            <Card variant="outlined" style={{ marginTop: '12px', flex: 1 }}>
              <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Typography variant="h5" fontFamily={"Libre Baskerville"} style={{ marginTop: '20px', marginBottom: '20px'}}>Results</Typography>
                {/* { renderPhotoAnalysisResults() }
                { platform.toLowerCase() === "poshmark" ? <Divider /> : null } */}
                <List>
                  {result?.rules?.map((item, index) => (
                    <React.Fragment key={index}>
                      <ListItem key={index}
                        secondaryAction={
                          item.value ? <Verified style={{ color: '#33cc99' }} /> : <Report style={{ color: '#f64f45' }} />
                        }>
                        <ListItemText
                          key={index}
                          primary={item.title}
                          secondary={item.subtitle}
                        >
                        </ListItemText>
                      </ListItem>
                      {index !== result?.rules?.length - 1 && <Divider component="li" />}
                    </React.Fragment>
                  ))}
                </List>
              </CardContent>
            </Card>
          </>
        }
      </Box>
    );
  };

  return (
    <div>
      <Helmet bodyAttributes={{ style: `background-color : ${colors.alabaster}` }}>
        <title>{`Listing Analyzer for ${platform}: Evaluate, and optimize your listings`}</title>
        <meta name="description" content={"With Listing Analyzer, you can effortlessly enhance your listings to boost visibility and increase sales with a single click."} />
        <meta property="og:title" content={`${platform} Listing Analyzer`} />
        <meta property="og:description" content="Improve your listings and make more sales." />
        <meta property="og:url" content={`https://poshwatch.io/listing-analyzer-${platform}`} />
        <meta property="og:type" content="website" />
        <link rel="canonical" href={`${window.location.origin}${canonicalUrl}`} />
      </Helmet>
      <ResponsiveAppBar />
      <Panel theme={lightTheme} style={{ minHeight: "400px" }}>
        <Container maxWidth='lg'>
          {renderHeading()}
          {renderResults()}
        </Container>
      </Panel>
      {platform === 'Poshmark' ? <AnalyzerVideo /> : <AnalyzerBenefits />}
      <AnalyzerDetails platform={platform} />
      <NewsletterPanel />
      <Footer />
    </div>
  );
};