/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { ResponsiveAppBar } from '../components/ResponsiveAppBar';
import { useLocation } from 'react-router-dom';
import { fetchListingAnalysis } from "../api/client";
import NewsletterPanel from '../components/NewsletterPanel';
import Panel from '../components/Panel';
import { 
  Card, 
  CardContent,
  Container,
  Typography,
  Button,
  Box,
  CircularProgress,
  TextField,
  Grid,
  Divider,
  Chip
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { colors } from '../customTheme';
import Footer from '../components/Footer';
import { Analytics } from 'monolib';
import useMediaQuery from '@mui/material/useMediaQuery';
import LocalCache from '../utils/LocalCache.ts';
import { HoverRating } from '../components/HoverRating';
import { calculateListingAge } from '../utils/helpers.js';

const EXPECTED_KEYS = {
  Title: "title",
  Cover_Photo: "coverShot",
  Pictures: "pictures",
  Listing_Age: "publishDate",
  Condition: "condition",
  Description: "description",
  Daily_Shares: "shareCount",
  Price: "price",
  Tags: "styleTags",
  Likes: "likeCount", 
  Colors: "colors",
};

const CompareListings = ({ title, subtitle, description, href }) => {
  const [secondListingURLError, setSecondListingURLError] = useState('');
  const [firstListingURLError, setFirstListingURLError] = useState('');
  const [error, setError] = useState(null);
  const [firstListingURL, setFirstListingURL] = useState('');
  const [secondListingURL, setSecondListingURL] = useState('');
  const [firstListingResults, setFirstListingResults] = useState(null);
  const [secondListingResults, setSecondListingResults] = useState(null);
  const compareCache = useRef(new LocalCache());
  const location = useLocation();
  const initialQueryRun = useRef(false);

  const [loadingFirst, setLoadingFirst] = useState(false);
  const [loadingSecond, setLoadingSecond] = useState(false);


  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const notPoshmark = (url) => {
    return !url.includes("posh.mk") && !url.includes("poshmark.ca") && !url.includes("poshmark.com");
  };

  const handleCheck = useCallback(async (url, listingNumber) => {
    if (listingNumber === 1 && loadingFirst) return;
    if (listingNumber === 2 && loadingSecond) return;

    if (!url) {
      if(listingNumber === 1) {
        setFirstListingURLError("Enter a valid listing URL to continue");
      } else {
        setSecondListingURLError("Enter a valid listing URL to continue");
      }
      return;
    }
    if (notPoshmark(url)) {
      if(listingNumber === 1) {
        setFirstListingURLError(`${url} is not a valid listing URL`);
      } else {
        setSecondListingURLError(`${url} is not a valid listing URL`);
      }
      return;
    }

    if (url.includes("closet")) {
      if(listingNumber === 1) {
        setFirstListingURLError(`Please use a listing URL not a closet URL`);
      } else {
        setSecondListingURLError(`Please use a listing URL not a closet URL`);
      }
      return;
    }

    if (firstListingURL === secondListingURL) {
      if(listingNumber === 1) {
        setFirstListingURLError(`Cannot compare the same URL, use a different url to continue`);
      } else {
        setSecondListingURLError(`Cannot compare the same URL, use a different url to continue`);
      }
      return;
    }

    if (listingNumber === 1) setLoadingFirst(true);
    if (listingNumber === 2) setLoadingSecond(true);

    Analytics.instance.track("rank_checker");
    const listingURL = listingNumber === 1 ? firstListingURL : secondListingURL;
   
    // let res = compareCache.current.get(url);
    // console.log("res", res)
    // if (res === null) {
      const res = await fetchListingAnalysis({ listingURL });
      // console.log("res", res)
      if(res?.response?.status === 404 || res?.response?.status === 500) {
        setError(res.message);
      } else {
        compareCache.current.set(url, res);
        if(listingNumber === 1) {
          setFirstListingResults(res);
        } else {
          setSecondListingResults(res);
        }
      }
    // }
    if (listingNumber === 1) setLoadingFirst(false);
    if (listingNumber === 2) setLoadingSecond(false);
  }, [firstListingURL, secondListingURL, loadingFirst, setLoadingFirst, loadingSecond, setLoadingSecond]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const query1Param = searchParams.get('q1');
    const query2Param = searchParams.get('q2');

    if (query1Param && query2Param) {
      setFirstListingURL(query1Param);
      setSecondListingURL(query2Param);
    }
  }, [location.search]);

  useEffect(() => {
    if (firstListingURL && secondListingURL && !initialQueryRun.current) {
      handleCheck(firstListingURL, 1);
      handleCheck(secondListingURL, 2);
      initialQueryRun.current = true;
    }
  }, [firstListingURL, secondListingURL, handleCheck]);

  const renderHeading = () => {
    return (
      <Card variant="outlined" style={{ marginBottom: '20px'}}>
        <CardContent style={{ display: 'flex', flexDirection: 'column',  margin: '20px'}}>
          <Typography mb={2} variant='h1' style={{ fontSize: "3rem", fontFamily: "Libre Baskerville"}} >{title}</Typography>
          <Typography mb={4} variant='body1' color="textSecondary">{subtitle}</Typography>
        </CardContent>
      </Card>
    )
  }

  const renderValue = (key, val) => {
    if(key === 'Colors') {
      return val.map((item) => item.name ).join(" ");
    } else if(key === 'Listing_Age') {
      const age = calculateListingAge(val);
      return `${age} Days`;
    } else if(key === 'Tags') {
      return val.join(", ");
    } else if(key === 'Cover_Photo') {
      return(
        <img 
          src={val} 
          alt="Cover Shot" 
          style={{ 
            width: '200px', // Adjust the width as needed
            height: '200px', // Adjust the height as needed
            objectFit: 'cover' // Ensures the image fits well within the given dimensions
          }} 
        />
      );
    } else if(key === 'Pictures') {
      return (
        <>
          {
            val.map((item, index) => {
             return <img 
                key={index}
                src={item.url_small} 
                alt="Cover Shot" 
                style={{ 
                  marginLeft: "12px",
                  width: '95px', // Adjust the width as needed
                  height: '95px', // Adjust the height as needed
                  objectFit: 'cover' // Ensures the image fits well within the given dimensions
                }} 
              />
            })
          }
        </>
      )
    } else {
      return val;
    }
  }

  const renderComparisons = (listingNumber, results) => {
    return(
      <>
        {
          Object.keys(EXPECTED_KEYS).map((key, index) => {
            return(
              <Card variant="outlined" style={{ marginBottom: '20px' }} key={index}>
                <CardContent style={{ display: 'flex', flexDirection: 'column', margin: '20px'}}>
                  <Box mb={3} key={index}>
                    <Box mb={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <Typography variant="h5" color="textPrimary" sx={{ alignItems: 'left' }}>
                        {key.replaceAll("_", " ")}
                      </Typography>
                      <Chip 
                        label={`Listing ${listingNumber}`} 
                        sx={{ 
                          backgroundColor: listingNumber === 1 ? 'purple' : 'green', 
                          color: 'white'
                        }}/>
                    </Box>
                    <Divider sx={{ marginBottom: '20px', width: '100%' }} />
                    <Typography variant="body1" color="textSecondary">
                      { results && results.listing ? renderValue(key, results?.listing[EXPECTED_KEYS[key]]) : '-----' }
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            )
          })
        }
        </>
    )
  }

  const renderListingURLInput = (listingNumber) => {
    const result = listingNumber === 1 ? firstListingResults : secondListingResults;
    const loading = listingNumber === 1 ? loadingFirst : loadingSecond;
    return(
      <Card variant="outlined" style={{ marginBottom: '20px' }}>
        <CardContent style={{ display: 'flex', flexDirection: 'column', margin: '20px'}}>
          <Box>
            <Box display="inline-flex" alignItems="center">
              <Typography mb={4} variant="body1" color="textSecondary">
                {`Listing ${listingNumber}`}
              </Typography>
              {/* {result && (
                <HoverRating value={result?.score.rating}/>
              )} */}
            </Box>
            <Divider sx={{ marginBottom: '40px', width: '100%' }} />
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <TextField
                size="small"
                label="Enter listing URL"
                variant="outlined"
                value={listingNumber === 1 ? firstListingURL : secondListingURL}
                style={{
                  marginBottom: '30px',
                  alignSelf: 'flex-start',
                  flex: 1,
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                }}
                InputProps={{
                  style: {
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                  },
                }}
                onChange={(e) => {
                  if (listingNumber === 1) {
                    setFirstListingURL(e.target.value);
                    setFirstListingURLError(null);
                  } else {
                    setSecondListingURL(e.target.value);
                    setSecondListingURLError(null);
                  }
                }}
                error={listingNumber === 1 ? Boolean(firstListingURLError) : Boolean(secondListingURLError)}
                helperText={listingNumber === 1 ? firstListingURLError : secondListingURLError}
              />
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  if (listingNumber === 1) {
                    handleCheck(firstListingURL, listingNumber);
                  } else {
                    handleCheck(secondListingURL, listingNumber);
                  }
                }}
                style={{
                  borderRadius: 10,
                  paddingRight: '12px',
                  height: 44,
                  marginTop: isMobile ? 10 : 0,
                  boxShadow: 'none',
                  backgroundColor: colors.mineShaft,
                  alignSelf: 'flex-start',
                  minWidth: 100,
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                }}
              >
                { loading ? <CircularProgress color="inherit"/> : "Search" }
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Card>
    )
  }

  const renderAnalysisSection = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          {renderListingURLInput(1, firstListingResults)}
          {renderComparisons(1, firstListingResults)}
        </Grid>
        <Grid item xs={12} md={6}>
          {renderListingURLInput(2, secondListingResults)}
          {renderComparisons(2, secondListingResults)}
        </Grid>
      </Grid>
    );
  }

  return (
    <div>
      <ResponsiveAppBar />
      <Helmet bodyAttributes={{ style: `background-color : ${colors.alabaster}` }}>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={href} />
      </Helmet>
      <Panel>
        <Container maxWidth="lg">
          {renderHeading()}
          {renderAnalysisSection()}
        </Container>
      </Panel >
      <NewsletterPanel />
      <Footer disableBgColor />
    </div >
  );
};

export default CompareListings;
