/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { lightTheme } from '../../customTheme';
import { ResponsiveAppBar } from '../../components/ResponsiveAppBar';
import Panel from '../../components/Panel';
import { 
  Card, 
  CardContent,
  Container,
  Typography,
  Button,
  Box,
  Tab,
  Tabs,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { colors } from '../../customTheme';
import Footer from '../../components/Footer';
import { HoverRating } from '../../components/HoverRating';
import { Analytics } from 'monolib';
import { getClosetReport } from "../../api/client";
import useMediaQuery from '@mui/material/useMediaQuery';
import { getUsernameFromUrl } from '../../utils/helpers';

const ClosetReport = ({ title, subtitle, description, href }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [error, setError] = useState(null);
  const [sucess, setSucess] = useState(false);
  const [closetURL, setClosetURL] = useState('');
  const [loading, setloading] = useState(false);
  const [analysis, setAnalysis] = useState([]);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isLg = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleAnalyze = async () => {
    if (loading) {
      return;
    }
    if (!closetURL) {
      setError("Enter a valid closet URL to continue");
      return;
    }
    if (!closetURL.includes("posh.mk") && !closetURL.includes("poshmark.ca") && !closetURL.includes("poshmark.com")) {
      setError(`${closetURL} is not a valid closet URL`);
      return;
    }  

    setloading(true);
    Analytics.instance.track("generate_closet_report");
    const username = getUsernameFromUrl(closetURL);
    const hostname = new URL(closetURL).hostname;
    const res = await getClosetReport({ username, hostname });
    console.log("res", res);
    if(res?.response?.status === 404 || res?.response?.status === 500){
      setError(res.message);
    } else {
      setAnalysis([]);
      setSucess(true);
      setloading(false);
    }
  };

  const renderHeading = () => {
    return (
      <Card variant="outlined">
        <CardContent style={{ display: 'flex', flexDirection: 'column',  margin: '20px'}}>
          <Typography mb={2} variant='h1' style={{ fontSize: "2rem", fontFamily: "Libre Baskerville"}} >{title}</Typography>
          <Typography mb={4} variant='body1' color="textSecondary">{subtitle}</Typography>
          <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} alignItems={isMobile ? 'stretch' : 'center'}>
            <TextField
              size="small"
              label="Enter your closet URL to get a report..."
              variant="outlined"
              fullWidth
              value={closetURL}
              style={{
                paddingRight: '12px',
              }}
              onChange={(e) => setClosetURL(e.target.value)}
              error={error ? true : false}
              helperText={error}
            />
            <Button 
              color="primary" 
              variant='contained' 
              onClick={() => handleAnalyze()}
              style={{
                borderRadius: 10,
                marginRight: "10px",
                height: 44,
                width: isMobile ? '100%' : 220,
                marginLeft: isMobile ? 0 : 10,
                marginTop: isMobile ? 10 : 0,
                boxShadow: 'none',
                backgroundColor: colors.mineShaft,
              }}>
              Run Analysis
            </Button>
          </Box>
        </CardContent>
      </Card>
    )
  }

  const renderResultsTable = () => {
    return(
      <TableContainer sx={{ border: '1px solid black' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Score</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Description</TableCell>
              {/* <TableCell>image</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {analysis.map((item, index) => (
              <TableRow key={index}>
                <TableCell><HoverRating value={item.score.rating} label={item.score.word_rating}/></TableCell>
                <TableCell>{item.title}</TableCell>
                <TableCell>{item.score.sentence_rating}</TableCell>
                {/* <TableCell>{item.img_url}</TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  const renderAnalysis = () => {
    return (
      <>
        {activeTab === 0 && (
          <>
            {loading ? (
              <div>
                <CircularProgress color="secondary" />
                <Typography variant="h6">Performing Analysis</Typography>
              </div>
            ) : analysis.length < 1 ? (
              <div>Run analysis to see report</div>
            ) : (
              renderResultsTable()
            )}
          </>
        )}
      </>
    );
  }

  return (
    <div>
      <ResponsiveAppBar />
      <Helmet bodyAttributes={{ style: `background-color : ${colors.alabaster}` }}>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={href} />
      </Helmet>
      <Panel theme={lightTheme} >
        <Container maxWidth="lg">
          {renderHeading()}
          <Box
            sx={{
              backgroundColor: 'white',
              padding: 2,
              marginTop: 6,
            }}
          >
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              sx={{ marginBottom: 2, padding: 2 }}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="Analysis" />
              <Tab label="Previous Reports" />
            </Tabs>
            <Box sx={{ paddingTop: 2, paddingLeft: 4, paddingBottom: 4, minHeight: '200px' }}>
              {renderAnalysis()}
              {activeTab === 1 && <div>Previous Reports</div>}
            </Box>
          </Box>
        </Container>
      </Panel >
      <Footer disableBgColor />
    </div >
  );
};

export default ClosetReport;
