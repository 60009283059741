import { colors } from '../customTheme';
import ProductResearch from '../images/how_to_source/product-research.png';

const howToFindLimitedComps = `
~~~~light,${colors.white}
# Can’t Find Poshmark Comps? What Next?

<div style="text-align: center; font-family: Inter; margin-bottom: 6px">
Last updated Sep 3, 2024
</div>
<div style="text-align: center; font-family: Inter">
Written by Alice
</div>
\n&nbsp;\n

So, you’re out sourcing, elbows-deep in a Goodwill bin, when you feel something promising. You pull it out and realize it might be a gem—a piece from a reputable brand in immaculate condition.

You’ve got a hunch it might do well on Poshmark, but a quick comp check leaves you empty-handed and the usual Google lens check also gives no clues.

What do you do next?

As a reseller, pricing your items correctly is crucial for making sales and maximizing profits. But what do you do when you can’t find comparable sales (comps) to guide your pricing or purchasing? Whether you’re at a thrift store, estate sale, or digging through bins at Goodwill, it’s inevitable that you’ll come across items that leave you scratching your head. 

Maybe it’s a brand you’ve never heard of, or perhaps it’s a rare or unique piece that defies easy categorization. In this article, we’ll walk you through the steps of how to price your items when comps are elusive, helping you make informed decisions so you can sell confidently.

~~~~light,${colors.white}
#### Reseller Forensics: Steps to Find Hard-to-Find Comps

I like to call this reseller forensics. 

Yes, there’s no crime scene or criminal on the loose, but profits are at stake and the process feels just as investigative. You gather clues (information), analyze the evidence (comps from similar items), and piece together the details to crack the case of how to price your item accurately.

~~~~~light,${colors.white}
##### 1. Start with Information Gathering

The first step in pricing anything—whether it’s a designer bag, a vintage dress, or an item from a standard mall brand is to gather as much information as possible. 

Here’s what you should focus on:

**Brand:** 

This one is probably the most important and usually not too hard to find if the logo is still intact. Some brands have more appeal and demand than others, which can significantly affect pricing. If you can't identify the brand you can post the tag or a picture of the item in online reseller communities.

**Color:** 

Certain colors might be more popular or rare, affecting the item's value.

**Category:**

Is it a shoulder bag, crossbody, tote, or clutch? Different sub categories of items are more in vogue than others. Like Crossbody bags are one of the best sellers right now.

**Materials:**

Leather, canvas, suede—materials play a big role in determining the value of an item. Real/natural materials will almost always be more valuable that faux materials.

**Condition:** 

Immaculate condition will always command a higher price, but some items with flaws depending on what they are, still do well.

**Branding:** 

If the item features the brand's signature elements—like Burberry’s iconic plaid pattern or other distinctive details from well-known brands—it will usually command a higher price.

Think of all of the above factors as price dials: the higher the brand value, the higher the demand. High-quality, well-kept, and unique vintage items often justify a higher price. But remember, you can’t price accurately without enough information.

~~~~light,${colors.white}
##### 2. Check Other Resale Platforms

If your initial search doesn’t yield results, then you have to widen the search radius. Fortunately, you have several other resources at your disposal:

**• eBay**

eBay is often the first stop for resellers looking for comps, and for good reason. With millions of listings, eBay offers a broad spectrum of items, from the common to the rare. Use eBay’s advanced search features to filter results by "Sold" listings, which will give you a better idea of the market value.

**• Other Resale Platforms**

If eBay comes up dry—there are other platforms to explore, like Mercari and Depop. But for older more unique items you are more likely to find it on eBay

**• Online Forums**

When mainstream marketplaces don’t have the answers, believe it or not, there are die-hard fan groups for almost every brand, even those you thought were 6 ft. under and haven't heard about in decades. Look for these dedicated groups on Facebook or Reddit. These communities are filled with members happy to help and will even gush over your find.

~~~~light,${colors.white}
##### 3. Check Vintage Resources

If the item you’re dealing with is vintage, pricing can become even trickier. Vintage items often have no direct comps, especially if they are rare or unique. However, there are a few steps you can take to get a ballpark figure:

**• Vintage-Specific Platforms** 

Platforms like Etsy and Ruby Lane specialize in vintage items. 

While the prices on these sites can sometimes be inflated, they offer a good starting point for figuring out how much similar items are listed for. Don't stop there are probably countless other sites that cover vintage items. Just check google for vintage <brand name>

**• Online Vintage Groups**

If you can’t find what you’re looking for on resale platforms, look into vintage-specific online communities. Facebook has countless groups dedicated to vintage fashion, accessories, and home goods. They even have groups 


~~~~light,${colors.white}
#### If you still can't find anything

You have 2 options:

**1. Price high and wait**

If you got it for free or at a deep discount and it's a unique style, price high and wait. 
​
A person who appreciates one-of-a-kind pieces may come along and pay the higher price. At the very least, the offers that come in will give you an idea of what people are willing to pay.

However, not every item is worth the investment, especially if you’re struggling to find comps. 

**2. Leave it**

your closet a favor and put it back. Leave it right there in the bin where you found it, and walk away. There are so many other things you could sell. The last thing you want is to have an item sit in your closet, taking up space and not making you any money. 

Only for you to have to re-donate it to the bin where you found it.

You don't have time for that.

~~~~light,${colors.white},${colors.slatePurple}
#### Tools & Resources

There is so much to do as a reseller. 

Sourcing, listing, research and the list goes on. To help you save time and make more sales we have a bunch of free tools to help.

To help you do better compp research on Poshmark use our [Product Research](https://poshwatch.io/product-research) Tool to uncover Poshmark’s fast-selling products, so you can purchase with confidence.
![Image of product research tool](${ProductResearch})

We have a few more tools as well. Just check the free tools section in the navigation section of our home page.


~~~~light,${colors.white}
#### Conclusion

Pricing items without comps can be challenging, but it’s also an opportunity to hone your reselling instincts. 

By gathering as much information as possible, using all available resources, and tapping into online communities, you can make educated pricing decisions that will help you sell your items confidently and profitably

Remember, always do your homework and research. Happy sourcing!`;

export default howToFindLimitedComps;
